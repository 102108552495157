import React from 'react';

function IENotSupported() {
  return (
    <iframe
      src="/ie-not-supported.html"
      title="dummy"
      style={{ width: '100vw', height: '100vh', border: 'none' }}
    />
  );
}

export default IENotSupported;
